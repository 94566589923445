/* eslint-disable @next/next/no-img-element */
import styles from '@buddieshr/styles/components/trustedBy.module.scss';
import NumberInstalls from './numberInstalls';
import Image from 'next/image';
// yext.com
// spotnana.com
// docplanner.com
// qure.ai
// routeware.com
// maymobility.com
// essilorusa.com
// mercari.com
// unglobalcompact.org
// joor.com
// gatik.ai
// safetywing.com
// goodr.com
// legalsoft.com
// one.pet
// reckon.com
// getcensus.com
// ouraring.com
// raywhite.com
// chatdesk.com

const Pictures = () => (
  <>
    <Image
      width={39}
      height={50}
      src="/img/clients/raywhite.com.png"
      alt="raywhite.com uses Buddieshr"
      style={{ width: 39, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={99}
      height={50}
      src="/img/clients/ouraring.com.png"
      alt="ouraring.com uses Buddieshr"
      style={{ width: 99, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={139}
      height={50}
      src="/img/clients/getcensus.com.svg"
      alt="getcensus.com uses Buddieshr"
      style={{ width: 139, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={139}
      height={50}
      src="/img/clients/reckon.com.png"
      alt="reckon.com uses Buddieshr"
      style={{ width: 139, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={99}
      height={50}
      src="/img/clients/goodr.com.png"
      alt="goodr.com uses Buddieshr"
      style={{ width: 99, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={139}
      height={50}
      src="/img/clients/gatik.ai.png"
      alt="gatik.ai uses Buddieshr"
      style={{ width: 139, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={90}
      height={50}
      src="/img/clients/joor.com.svg"
      alt="joor.com uses Buddieshr"
      style={{ width: 90, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={139}
      height={50}
      src="/img/clients/unglobalcompact.org.svg"
      alt="unglobalcompact.org uses Buddieshr"
      style={{ width: 139, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={119}
      height={50}
      src="/img/clients/maymobility.com.png"
      alt="maymobility.com uses Buddieshr"
      style={{ width: 119, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={139}
      height={50}
      src="/img/clients/routeware.com.png"
      alt="routeware.com uses Buddieshr"
      style={{ width: 139, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={129}
      height={50}
      src="/img/clients/qure.ai.svg"
      alt="qure.ai uses Buddieshr"
      style={{ width: 129, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={44}
      height={50}
      src="/img/clients/yext.com.png"
      alt="yext.com uses Buddieshr"
      style={{ width: 44, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={139}
      height={50}
      src="/img/clients/spotnana.png"
      alt="spotnana.com uses Buddieshr"
      style={{ width: 139, marginTop: '12px', height: 'auto' }}
    />
    <Image
      width={129}
      height={50}
      src="/img/clients/safetywing.png"
      alt="safetywing uses Buddieshr"
      style={{ width: 129, marginTop: '24px', height: 'auto' }}
    />
    <Image
      width={159}
      height={50}
      src="/img/clients/mercari.svg"
      alt="mercari uses Buddieshr"
      style={{ width: 159, marginTop: '18px', height: 'auto' }}
    />

    <Image
      width={149}
      height={50}
      src="/img/clients/chatdesk.png"
      alt="chatdesk uses Buddieshr"
      style={{ width: 149, height: 'auto', marginTop: '14px' }}
    />
    <Image
      width={145}
      height={50}
      src="/img/clients/essilor.png"
      alt="essilor uses Buddieshr"
      style={{ width: 145, height: 'auto', marginTop: '14px' }}
    />

    <Image
      width={240}
      height={50}
      src="/img/clients/docplanner.png"
      alt="docplanner group trusts Buddieshr"
      style={{ width: 240, height: 'auto', marginTop: '12px' }}
    />
    {/* <Image
width={50}
height={50}
      src="/img/clients/resonate.png"
      alt="resonate uses Buddieshr"
      style={{ width: 159, marginTop: "18px", height: "auto" }}
    /> */}
    {/* <Image
width={50}
height={50}
      src="/img/clients/forbes.png"
      alt="Forbes uses Buddieshr"
      style={{ width: 100, height: "auto", marginTop: "12px" }}
    />
    <Image
width={50}
height={50}
      src="/img/clients/slack.png"
      alt="slack trusts Buddieshr"
      style={{ width: 120, height: "auto", marginTop: "12px" }}
    /> */}
    {/* <Image
width={50}
height={50}
      src="/img/clients/bayer.png"
      alt="bayer uses Buddieshr"
      style={{ width: 49, height: "auto", marginTop: "14px" }}
    />
    <Image
width={50}
height={50}
      src="/img/clients/adobe.png"
      alt="adobe uses Buddieshr"
      style={{ width: 145, height: "auto", marginTop: "14px" }}
    /> */}
    {/* <Image
width={50}
height={50}
      src="/img/clients/costacoffee.png"
      alt="Costa coffee uses Buddieshr to celebrate birthdays in Slack or Microsoft Teams"
      style={{ width: 100, marginTop: "18px", height: "auto" }}
    />
    <Image
width={50}
height={50}
      src="/img/clients/microsoft.png"
      alt="microsoft trusts Buddieshr"
      style={{ width: 140, height: "auto", marginTop: "12px" }}
    /> */}
    {/* <Image
width={50}
height={50}
      src="/img/clients/worklib.png"
      alt="worklib uses Buddieshr"
      style={{ width: 155, height: "auto", marginTop: "14px" }}
    /> */}
  </>
);

const TrustedBy = ({ withoutBackground = false, hideInstall = false }) => (
  <div
    className={styles.wrapper}
    style={withoutBackground ? { background: 'transparent' } : {}}
  >
    <div className={styles.title}>
      Join thousands of <b>badass companies</b> creating the culture they’ve
      always dreamed of!
    </div>
    <div className={styles.UsedBy_inner_wrapper}>
      <div className={`${styles.UsedBy_inner} ${styles.animate}`}>
        <Pictures />
        <Pictures />
        <Pictures />
        <Pictures />
      </div>
    </div>
    {!hideInstall && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '14px',
        }}
      >
        <NumberInstalls />
      </div>
    )}
  </div>
);

export default TrustedBy;
